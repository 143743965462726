<template>
  <div class="quickAction-container worktop-new-container" ref="tabs">
    <module-box :appModule="appModule">
      <div class="selectCommunity" slot="search" >
        <v-select2 placeholder="查询所属项目"
                      v-show="!isCommunityType"
                      v-model="commonProps.communityId"
                      v-bind="communityParams"
                      @onChange="communityChange"
                      :width="180"></v-select2>
      </div>
      <!-- <tabs-panel :tabs="tabs"
              :tabRouter="false"
              :activeLabel.sync="activeLabel"
      ></tabs-panel> -->
       <!-- v-if="commonProps.communityId" -->
      <el-tabs v-model="activeLabel" class="tabs-container" v-if="commonProps.communityId">
        <el-tab-pane :label="item.label" :name="item.label" v-for="(item,index) in tabs" :key="index">
          <span slot="label">
            <img :src="activeLabel == item.label ? item.act_icon : item.icon" alt="">
            {{item.label}}
          </span>
            <component :is="item.component" v-bind="item.props"></component>
        </el-tab-pane>
      </el-tabs>
      <div class="empty-wrapper" v-if="!commonProps.communityId">
        <img src="../images/empty.png">
        <span style="display:block">请先选择项目</span>
      </div>
    </module-box>
  </div>
</template>

<script>
import { Tabs } from 'element-ui'
import { TabsPanel } from 'components/bussiness'
import { communityParams } from 'common/select2Params'
import order from './order'
import deposit from './deposit'
import express from './express'
import service from './service'
import ModuleBox from '../ModuleBox'

import gongdanluru from '../images/gongdanluru.png'
import act_gongdanluru from '../images/act-gongdanluru.png'
import kuaidiguanli from '../images/kuaidiguanli.png'
import act_kuaidiguanli from '../images/act-kuaidiguanli.png'
import wupinzhuangru from '../images/wupinzhuangru.png'
import act_wupinzhuangru from '../images/act-wupinzhuangru.png'
import shenghuofuwu from '../images/shenghuofuwu.png'
import act_shenghuofuwu from '../images/act-shenghuofuwu.png'

export default {
  name: '',
  components: {
    TabsPanel,
    ModuleBox,
    [Tabs.name]: Tabs
  },
  props: {
    width: Number,
    leftIndex: {
      type: Number,
      default: 0
    },
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    communityId () {
      return this.$store.state.workTopStore.communityId
    },
    isCommunityType () {
      return this.$store.state.userInfo.userType === '101'
    },
    userCommunityId () {
      return this.$store.state.userInfo.communityId
    }
  },
  mounted () {
    let _this_ = this
    this.$nextTick(() => {
      _this_.getWidth()
    })
      this.tabs = this.appModule.childreds.map(item => this.ComponentMap[item.classification])
 
      if (this.isCommunityType) {
        this.commonProps.communityId = this.userCommunityId

      }
      // 需要选择项目的时候不去给予默认的communityId,退出的时候workTopStore.communityId数据没有被清空
      //  if (this.communityId) {
      //   this.commonProps.communityId = this.communityId
      //   console.log('communityId',this.communityId)
      // }
      // console.log('this.$store.state.workTopStore.communityId',this.$store.state.workTopStore.communityId)
      // console.log('this.commonProps',this.commonProps)
    
  },
  data () {
    return {
      title: '快捷操作',
      communityParams,
      tabs: [],
      activeLabel: '工单录入',
      commonProps: {
        width: 0,
        communityId: '',
        leftIndex: this.leftIndex
      },
      ComponentMap: {},
      gongdanluru,
      act_gongdanluru,
      kuaidiguanli,
      act_kuaidiguanli,
      wupinzhuangru,
      act_wupinzhuangru,
      shenghuofuwu,
      act_shenghuofuwu
    }
  },
  created () {
    this.ComponentMap = {
      'entryWorkOrders': {
        label: '工单录入',
        icon: this.gongdanluru,
        act_icon: this.act_gongdanluru,
        component: order,
        props: this.commonProps
      },
      'fastManagement': {
        label: '快递管理',
        icon: this.kuaidiguanli,
        act_icon: this.act_kuaidiguanli,
        component: express,
        props: this.commonProps
      },
      'itemStorage': {
        label: '物品寄存',
        icon: this.wupinzhuangru,
        act_icon: this.act_wupinzhuangru,
        component: deposit,
        props: this.commonProps
      },
      'lifeService': {
        label: '生活服务',
        icon: this.shenghuofuwu,
        act_icon: this.act_shenghuofuwu,
        component: service,
        props: this.commonProps
      }
    }
  },
  methods: {
    getWidth () {
      let _this_ = this
      let tabWidth = _this_.$refs.tabs.clientWidth
      if (tabWidth <= 500) {
        console.log('tabWidth', tabWidth)
        _this_.commonProps.width = tabWidth - 168
      } else {
        _this_.commonProps.width = tabWidth * 0.65
        console.log('0.65', _this_.commonProps.width)
      }
    },
    //比mounted慢触发
    communityChange (item) {
  
      if (item && item.id) {
        this.$store.commit('workTopStore/setCommunityId', item.id)

      } else {
        this.$store.commit('workTopStore/setCommunityId', '')
       
      }
    }
  },
  watch: {
    leftIndex (newValue) {
      this.getWidth()
    },
    appModule (newValue) {
      this.getWidth()
      this.tabs = this.appModule.childreds.map(item => this.ComponentMap[item.classification])
      const hasLabel = this.tabs.filter(item => {
        return item.label === this.activeLabel
      }).length > 0
      if (!hasLabel) {
        this.activeLabel = this.tabs[0].label
      }
    },
    'commonProps.communityId': function () {
      if (this.commonProps.communityId) {
        const hasLabel = this.tabs.filter(item => {
          return item.label === this.activeLabel
        }).length > 0
        if (!hasLabel) {
          this.activeLabel = this.tabs[0].label
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quickAction-container {
  text-align: left;
  .title {
    line-height: 48px;
  }
  .selectCommunity {
    display: inline-block;
    padding-left: 16px;
    padding-top: 5px;
  }
  .tabs-container {
    padding: 0 24px;
  }
  .empty-wrapper {
    height: 350px;
    color: #aaaaaa;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 200px;
      height: 200px;
    }
  }
  ::v-deep .switch {
    .title {
      display: block;
      margin: 20px 0;
      font-size: 12px;
      margin-left: 12px;
    }
    .bussiness-container {
      display: flex;
      flex-wrap:wrap;
      .bussiness-item {
        width: 362px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin: 0 12px 12px 12px;
        border-radius:6px;
        box-shadow:0px 0px 16px 0px rgba(27,140,242,0.15);
        img {
          height:48px;
          width: 48px;
        }
        &:hover {
          transform: translateY(-5px);
          transition: transform 0.2s;
          transition-timing-function: ease;
          cursor: pointer;
        }
        .item-title {
          padding-left: 20px;
        }
      }
    }
  }
}

</style>
<style scoped>
 .selectCommunity >>> .select2Parent .dropdown-toggle {
      height: 35px;
      /* background-color: #E3F0FF;
      border: 1px solid #E3F0FF; */
  }
  .selectCommunity >>> .select2Parent input {
      height: 32px;
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 32px;
      padding: 0 6px;
  }
  .selectCommunity >>> .select2Parent input::-webkit-input-placeholder {
      /* color: #1B8CF2; */
  }
  .selectCommunity >>> .select2Parent .dropdown-toggle .v-select .vs__actions {
    /* background-color: #E3F0FF; */
  }
  .selectCommunity >>> .select2Parent .vs__selected-options span.selected-tag {
    /* color: #1B8CF2; */
    line-height: 32px;
  }
  .worktop-new-container >>> .el-tabs__nav-wrap::after {
      height: 0px;
}
.worktop-new-container >>> .el-tabs__nav {
      display: flex;
      flex-flow: wrap;
}
.worktop-new-container >>> .el-tabs__active-bar {
      height: 0px;
}
.worktop-new-container >>> .el-tabs__item {
    width: 172px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 20px 10px;
    color: #919FB0;
    background: #F8F9F9;
    border-radius: 4px;
}
.worktop-new-container >>> .el-tabs__item.is-active {
  background: #5A8DEE;
  color: #fff;
}
.worktop-new-container >>> .el-tabs__item img {
  width: 22px;
  height: 22px;
  vertical-align: sub;
}
.worktop-new-container >>> .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
</style>
<style lang="scss">
</style>
