import axios from 'axios'
import store from '@/store/index'
// 获取园区应用列表
const getSubjectURL = `${API_CONFIG.baseURL}kpi/community/activeUserCommon`
// 获取用户列表
const getOwnerListURL = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// 根据快递单号获取快递公司
const getExpCompanyListByExpNumURL = `${API_CONFIG.butlerBaseURL}express/getExpCompanyListByExpNum`
// 获取园区快递存放位置
const getCommunityExpressStoreURL = `${API_CONFIG.butlerBaseURL}workbench/express/store/list`
// 登记快递
const saveExpressURL = `${API_CONFIG.butlerBaseURL}workbench/express`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// 查询快递二维码
const getExpListURL = `${API_CONFIG.butlerBaseURL}workbench/express/getExpListByCode`
// 领取快递二维码
const signExpListURL = `${API_CONFIG.butlerBaseURL}workbench/express/acceptExpress`
// 保存物品寄存
const saveDepositURL = `${API_CONFIG.butlerBaseURL}workbench/deposit`
// 根据项目id获取门店名称
const getServiceNameURL = `${API_CONFIG.butlerBaseURL}store/getStoreInfoByBelongCommunityId`
// 查询分类名称
const queryCategroyURL = `${API_CONFIG.butlerBaseURL}productCategory/getProductCategoryList`
// 通过商品
const queryGoodsListURL = `${API_CONFIG.butlerBaseURL}storeProduct/getStoreProductListByLabelId`

// 根据用户姓名和手机号查询用户信息
const searchUserInfoURL = `${API_CONFIG.butlerBaseURL}workbench/getOwnerInfos`
//  根据用户Id和组织id和获取房号
const queryHouseURL = `${API_CONFIG.butlerBaseURL}workbench/getHouseInfos`
// 根据园区ID获取苑幢
const queryBuildURL = `${API_CONFIG.butlerBaseURL}workbench/workbenchQueryGardenBuilding`
// 根据园区ID获取单元室
const queryRoomURL = `${API_CONFIG.butlerBaseURL}workbench/workbenchQueryUnitRoom`
// 新增导入用户信息
const addUserURL = `${API_CONFIG.butlerBaseURL}workbench/addImportOwnerInfo`
// 获取门店详情
const queryStoreDetalURL = `${API_CONFIG.butlerBaseURL}store/getStoreServiceInfo`
// 生活服务新增工单
const saveShopOrderURL = `${API_CONFIG.butlerBaseURL}order/insertNewOrderInShoppingCart`

// ----工单录入
// 查询工单场景
const queryOrderTypeURL = `${API_CONFIG.butlerBaseURL}workbench/getOrderTypeList`
// 查询园区服务类型
const querySubTypeURL = `${API_CONFIG.butlerBaseURL}workbench/querySubType`
// 查询维修类别
const queryMaintainURL = `${API_CONFIG.butlerBaseURL}workbench/maintainCategory/`
// 查询服务时间
const queryServiceTimeURL = `${API_CONFIG.baseURL}serverSubjectAction!queryTime.action`
// 工单录入
const saveOrderURL = `${API_CONFIG.butlerBaseURL}butler/operateOrder/addReport`

// 判断新老工单
const checkNewWorkOrder = `${API_CONFIG.butlerBaseURL}butler/operateOrder/checkNewWorkOrder`

// 工作台用 根据公司id获取 工单树 接口
const getTreeByRegionIdURL = `${API_CONFIG.taskDispatchURL}dispatch/category/getTreeByRegion`

// 新增工单
const addNewReport = `${API_CONFIG.taskDispatchURL}dispatch/task/add`

// 查询房号
const select2RoomIdUrl = `${API_CONFIG.butlerBaseURL}orgInfo/house/by/communityId`

// 根据房号id查询用户信息
const getOwnerByRoomId = `${API_CONFIG.butlerBaseURL}ownerInfo/inviterList`

const userParams = {
  searchUrl: searchUserInfoURL,
  request: {
    text: 'ownerName',
    value: 'userId'
  }
}
const postReport = (data) =>{
  let url = '';
  //得根据域名来判断了
  if(window.origin === 'https://yshk8s.wisharetec.com' || window.origin === 'https://shushuapitest.4001113900.com:10020') {
      url = `https://saasdev.wisharetec.com/api/workorder/task/report`
  }else {
      url = ` https://saas.wisharetec.com/api/workorder/task/report`
  }
  return axios({
    method:'post',
    url:url,
    data,
    headers:{
      client:'ysh-pc',
      token:store.state.token,
      roomId:data.roomId
    }
  })
}
const getV3Tree = (data) =>{
  // let url = `https://saasdev.wisharetec.com/api/workorder/category/query/tree`;
  let url =''
  //得根据域名来判断了
  if(window.origin === 'https://yshk8s.wisharetec.com' || window.origin === 'https://shushuapitest.4001113900.com:10020') {
      url = `https://saasdev.wisharetec.com/api/workorder/category/query/tree`
  }else {
      url = ` https://saas.wisharetec.com/api/workorder/category/query/tree`
  }
  return axios({
    method:'get',
    url:url,
    params:data,
    headers:{
      client:'ysh-pc',
      token:store.state.token,
    }
  })
}
export {
  addNewReport,
  checkNewWorkOrder,
  getTreeByRegionIdURL,
  getSubjectURL,
  getOwnerListURL,
  userParams,
  getCommunityExpressStoreURL,
  uploadURL,
  getExpListURL,
  signExpListURL,
  getServiceNameURL,
  queryCategroyURL,
  queryGoodsListURL,
  searchUserInfoURL,
  queryBuildURL,
  queryRoomURL,
  queryHouseURL,
  addUserURL,
  queryStoreDetalURL,
  saveShopOrderURL,
  queryOrderTypeURL,
  querySubTypeURL,
  queryMaintainURL,
  queryServiceTimeURL,
  saveOrderURL,
  getExpCompanyListByExpNumURL,
  saveExpressURL,
  saveDepositURL,
  select2RoomIdUrl,
  getOwnerByRoomId,
  postReport,
  getV3Tree
}
