// 保存接口 
// const createSubmitUrl = `${API_CONFIG.baseURL}serverOwnerAction!savebase.action`
// 新增未注册用户
const createSubmitUrl = `${API_CONFIG.butlerBaseURL}user/register/addRegisterUserBase`
// 新修改信息接口
const updateSubmitUrl = `${API_CONFIG.butlerBaseURL}user/register/updateRegisterUserBase`
// const updateSubmitUrl = `${API_CONFIG.baseURL}serverOwnerAction!updatebase.action`
// const updateSubmitUrl = `${API_CONFIG.butlerBaseURL}user/information/updatebase`
const getInitCodeWordList = `${API_CONFIG.butlerBaseURL}getInitCodeWordList`
const select2CommunityIdUrl = `${API_CONFIG.baseURL}communityStoreSubjectAction!viewCommunity.action`
const select2RoomIdUrl = `${API_CONFIG.butlerBaseURL}orgInfo/house/by/communityId`
const multiSelectUrl = `${API_CONFIG.controlBaseURL}label/getLabelList?status=1&labelType=4`
const getCategoryIdUrl = `${API_CONFIG.controlBaseURL}label/getCategoryByParentId`
const getNationUrl = `${API_CONFIG.butlerBaseURL}perfectOwner/getNation`
const createHouseInfoUrl = `${API_CONFIG.butlerBaseURL}user/information/asset/add`
const updateHouseInfoUrl = `${API_CONFIG.butlerBaseURL}user/information/asset/update`
const getRegisterDetailUrl = `${API_CONFIG.butlerBaseURL}user/information/register/detail`
const resetPasswordUrl = `${API_CONFIG.butlerBaseURL}manager/resetPwd`
// const resetPasswordUrl = `${API_CONFIG.baseURL}serverUserAction!resetPwg.action`
const getUnRegisterDetailUrl = `${API_CONFIG.butlerBaseURL}user/information/unregister/detail`
const getOrgInfoUrl = `${API_CONFIG.butlerBaseURL}orgInfo/by/communityIdAndHouseId`
const getRoomInfoUrl = `${API_CONFIG.butlerBaseURL}orgInfo/house/by/communityId`
const delStructUserInfoUrl = `${API_CONFIG.butlerBaseURL}user/delStructUserInfo`
// 获取操作日志接口
const getLogInfoUrl = `${API_CONFIG.butlerBaseURL}user/register/getManageOperationLogList`
const addJobInfoURL = `${API_CONFIG.butlerBaseURL}ownerExpandInfo/addOwnerWorkInfo`
const addPersonInfoURL = `${API_CONFIG.butlerBaseURL}ownerExpandInfo/addOwnerPersonalInfo`

// 获取工单记录接口
const getWorkOrderListURL = `${API_CONFIG.origin}task-dispatch/dispatch/task/search`
// 获取出入记录接口
const getOwnerPassageLog = `${API_CONFIG.butlerBaseURL}getOwnerPassageLog`

export {
  getInitCodeWordList,
  createSubmitUrl,
  updateSubmitUrl,
  select2CommunityIdUrl,
  select2RoomIdUrl,
  multiSelectUrl,
  getCategoryIdUrl,
  getNationUrl,
  createHouseInfoUrl,
  updateHouseInfoUrl,
  getRegisterDetailUrl,
  resetPasswordUrl,
  getUnRegisterDetailUrl,
  getOrgInfoUrl,
  getRoomInfoUrl,
  delStructUserInfoUrl,
  getLogInfoUrl,
  addJobInfoURL,
  addPersonInfoURL,
  getWorkOrderListURL,
  getOwnerPassageLog,
}
