var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "diposit-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dipositForm",
          attrs: { model: _vm.form, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "选择分类",
                prop: "type",
                rules: [
                  { required: true, message: "请选择分类", trigger: "blur" },
                ],
              },
            },
            [
              _c("checkbox-plus", {
                attrs: { options: _vm.checkoptions, value: _vm.form.type },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "type", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "业主信息",
                prop: "userId",
                rules: [
                  { required: true, message: "请选择用户", trigger: "change" },
                ],
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    ref: "userSelect",
                    attrs: {
                      handleData: _vm.handleUserData,
                      placeholder: "查询用户",
                      subjoin: _vm.communitySubjoin,
                      width: _vm.width,
                    },
                    on: { onChange: _vm.userChange },
                    model: {
                      value: _vm.form.userId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userId", $$v)
                      },
                      expression: "form.userId",
                    },
                  },
                  "v-select2",
                  _vm.userParams,
                  false
                )
              ),
              _c("add-user", {
                attrs: { communityId: _vm.communityId, userSource: 7 },
                on: { getNewUser: (userId) => (_vm.form.userId = userId) },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "房号",
                prop: "roomId",
                rules: [
                  { required: true, message: "请选择房号", trigger: "change" },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.houseOps, width: _vm.width },
                model: {
                  value: _vm.form.roomId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roomId", $$v)
                  },
                  expression: "form.roomId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "寄存地点",
                prop: "address",
                rules: [
                  {
                    required: true,
                    message: "请输入寄存地点",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "支持最长输入10位",
                  width: _vm.width,
                  maxlength: 10,
                },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物品照片" } },
            [
              _c("v-uploader", {
                attrs: {
                  action: _vm.uploadURL,
                  imgUrls: _vm.form.imageUrls,
                  limit: 4,
                },
                on: {
                  "update:imgUrls": function ($event) {
                    return _vm.$set(_vm.form, "imageUrls", $event)
                  },
                  "update:img-urls": function ($event) {
                    return _vm.$set(_vm.form, "imageUrls", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物品内容",
                prop: "remark",
                rules: [
                  {
                    required: true,
                    message: "请填写物品内容",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-textarea", {
                attrs: { rows: 3, width: _vm.width, maxlength: 80 },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "v-button",
                {
                  attrs: { width: _vm.width, disabled: _vm.clickDisabel },
                  on: { click: _vm.saveDiposit },
                },
                [_vm._v("确认提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }