<template>
  <div class="addUser-container">
    <v-button type="text"
              @click="dialogShow">
      没有？去添加一条业主信息
    </v-button>
    <el-dialog :visible.sync="disable"
               width="500px"
               title="新增用户"
               custom-class="addUser-dialog">
      <el-form class="addUserForm"
               label-width="150px"
               :model="form"
               ref="addUserForm">
        <el-form-item label="苑幢"
                      :rules="[{ required: true, message: '请选择苑幢', trigger: 'change' }]"
                      prop="building">
          <v-select2 ref="building"
                     v-model="form.building"
                     v-bind="buildingParams"
                     :subjoin="{ communityId: communityId }"
                     :width="width"
                     :handleData="handleBuildingData"
                     @onChange="onBuildingChange"></v-select2>
        </el-form-item>
        <el-form-item label="单元室"
                      v-if="hasHouseInfo"
                      :rules="[{ required: hasHouseInfo, message: '请选择单元室', trigger: 'change' }]"
                      prop="roomId">
          <v-select2 v-model="form.roomId"
                     v-bind="roomParams"
                     :subjoin="roomExParams"
                     :width="width"
                     :handleData="handleRoomData"
                     ref="roomId"
                     :disabled="!this.form.buildingNumber"
                     @onChange="onRoomChange"></v-select2>
        </el-form-item>
        <el-form-item label="用户姓名"
                      :rules="[{ required: true, message: '请输入用户姓名', trigger: 'blur' }]"
                      prop="ownerName">
          <v-input v-model="form.ownerName"
                   :maxlength="10"
                   placeholder="请输入用户姓名"
                   :width="width"></v-input>
        </el-form-item>
        <el-form-item label="用户手机"
                      :rules="[{ required: true, message: '请输入用户手机', trigger: 'blur' }]"
                      prop="ownerPhone">
          <v-input v-model="form.ownerPhone"
                   :width="width"
                   placeholder="请输入用户手机号码"
                   :maxlength="11"></v-input>
        </el-form-item>
        <el-form-item label="身份"
                      :rules="[{ required: true, message: '请选择身份', trigger: 'change' }]"
                      prop="userType">
          <v-select :width="width"
                    v-model="form.userType"
                    :options="userTypeOps" />
        </el-form-item>
        <el-form-item label="是否付款联系人"
                      :rules="[{ required: true, message: '请选择是否付款联系人', trigger: 'change' }]"
                      prop="isPayment">
          <v-select :width="width"
                    v-model="form.isPayment"
                    :options="isOps" />
        </el-form-item>
        <el-form-item label="是否短信联系人"
                      :rules="[{ required: true, message: '请选择是否短信联系人', trigger: 'change' }]"
                      prop="isMessage">
          <v-select :width="width"
                    v-model="form.isMessage"
                    :options="isOps" />
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <v-button type="default"
                  text="取消"
                  @click="disable=false"></v-button>
        <v-button style="margin-left:10px"
                  @click="submit"
                  text="保存"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { queryBuildURL, queryRoomURL, addUserURL } from './api'
import { setIsOps, setUserTypeOps } from './map'
import _ from 'lodash'
export default {
  name: 'addUser',
  props: {
    communityId: String,
    userSource: {
      type: Number,
      default: 4
    }
  },
  data () {
    return {
      communityParams: {},
      width: 200,
      disable: false,
      form: {
        communityId: '',
        roomId: '',
        ownerName: '',
        ownerPhone: '',
        userType: undefined,
        isPayment: undefined,
        isMessage: undefined,
        blockName: undefined,
        buildingNumber: undefined,
        isExamine: 1,
        userSource: this.userSource
      },
      roomId: '',
      buildingParams: {
        searchUrl: queryBuildURL,
        request: {
          text: 'blockBuildingName'
        },
        response: {
          value: 'id',
          text: 'name'
        }
      },
      buildingNumber: undefined,
      blockName: undefined,
      hasHouseInfo: true,
      roomParams: {
        searchUrl: queryRoomURL,
        request: {
          text: 'unitRoom',
          value: 'id'
        },
        response: {
          value: 'id',
          text: 'name'
        }
      },
      isOps: setIsOps(),
      userTypeOps: setUserTypeOps()
    }
  },
  watch: {
    communityId (value) {
      this.clearFormData()
      this.form.communityId = value
      this.form.userSource = this.userSource
    }
  },

  computed: {

    roomExParams () {
      let params = {
        communityId: this.communityId
      }
      if (this.buildingNumber && this.buildingNumber.length) {
        params.buildingNum = this.buildingNumber
      }
      if (this.blockName && this.blockName.length) {
        params.blockName = this.blockName
      }

      return params
    }
  },
  created () {
    this.communityParams = {
      ...communityParams,
      isText: true
    }
  },
  methods: {
    dialogShow () {
      this.disable = true
    },
    handleBuildingData (data) {
      return data.map(item => {
        let name = [item.blockName, item.buildingNumber].filter(text => text).join('-')
        return {
          ...item,
          name,
          id: item.buildingNumber
        }
      })
    },
    onBuildingChange (item) {
      this.form.roomId = undefined
      if (item) {
        this.blockName = item.blockName
        this.buildingNumber = item.buildingNumber
        this.hasHouseInfo = item.hasHouseInfo === 1
        this.form.blockName = item.blockName
        this.form.buildingNumber = item.buildingNumber
        // 如果没有单元室，使用服务端返回的roomId
        if (!this.hasHouseInfo) {
          this.roomId = item.roomId
        }
      } else {
        this.form.blockName = ''
        this.form.buildingNumber = ''
      }
    },
    handleRoomData (data) {
      return data.map(item => {
        let name = [item.roomNum, item.unit].filter(text => text).join('-')
        return {
          name,
          id: item.roomId
        }
      })
    },
    onRoomChange (item) {

    },
    clearFormData () {
      this.form = {
        communityId: this.communityId,
        roomId: '',
        ownerName: '',
        ownerPhone: '',
        userType: undefined,
        isPayment: undefined,
        isMessage: undefined,
        blockName: undefined,
        buildingNumber: undefined,
        isExamine: 1,
        userSource: this.userSource
      }
      this.hasHouseInfo = true
      this.$refs.building && this.$refs.building.clearValue()
      this.$refs.roomId && this.$refs.roomId.clearValue()
      this.$refs.addUserForm && this.$refs.addUserForm.resetFields()
    },
    async submit () {
      try {
        await this.$refs.addUserForm.validate()
      } catch (error) {
        return false
      }
      let postPrams = _.cloneDeep(this.form)
      postPrams.roomId = this.hasHouseInfo ? postPrams.roomId : this.roomId

      let { status, data } = await this.$axios.post(addUserURL, {
        ...postPrams
      })
      if (status === 100) {
        this.disable = false
        this.$message.success('新增成功')
        this.clearFormData()
        this.$emit('getNewUser', data)
      }
    }
  },
  mounted () {
    this.form.communityId = this.communityId
  }
}
</script>

<style lang="scss" scoped>
.addUser-dialog {
  padding: 24px;
  .addUserForm {
    > .el-form-item {
      text-align: left;
      margin-bottom: 25px;
    }
  }
}
</style>
