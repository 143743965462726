var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "tabs", staticClass: "quickAction-container worktop-new-container" },
    [
      _c(
        "module-box",
        { attrs: { appModule: _vm.appModule } },
        [
          _c(
            "div",
            {
              staticClass: "selectCommunity",
              attrs: { slot: "search" },
              slot: "search",
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isCommunityType,
                        expression: "!isCommunityType",
                      },
                    ],
                    attrs: { placeholder: "查询所属项目", width: 180 },
                    on: { onChange: _vm.communityChange },
                    model: {
                      value: _vm.commonProps.communityId,
                      callback: function ($$v) {
                        _vm.$set(_vm.commonProps, "communityId", $$v)
                      },
                      expression: "commonProps.communityId",
                    },
                  },
                  "v-select2",
                  _vm.communityParams,
                  false
                )
              ),
            ],
            1
          ),
          _vm.commonProps.communityId
            ? _c(
                "el-tabs",
                {
                  staticClass: "tabs-container",
                  model: {
                    value: _vm.activeLabel,
                    callback: function ($$v) {
                      _vm.activeLabel = $$v
                    },
                    expression: "activeLabel",
                  },
                },
                _vm._l(_vm.tabs, function (item, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: item.label, name: item.label },
                    },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.activeLabel == item.label
                                ? item.act_icon
                                : item.icon,
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " + _vm._s(item.label) + "\n        "
                        ),
                      ]),
                      _c(
                        item.component,
                        _vm._b(
                          { tag: "component" },
                          "component",
                          item.props,
                          false
                        )
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          !_vm.commonProps.communityId
            ? _c("div", { staticClass: "empty-wrapper" }, [
                _c("img", { attrs: { src: require("../images/empty.png") } }),
                _c("span", { staticStyle: { display: "block" } }, [
                  _vm._v("请先选择项目"),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }