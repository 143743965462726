var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "service-form",
          attrs: { model: _vm.form, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "用户信息",
                prop: "userId",
                rules: [
                  { required: true, message: "请选择用户", trigger: "change" },
                ],
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    ref: "userSelect",
                    attrs: {
                      handleData: _vm.handleUserData,
                      placeholder: "查询用户",
                      subjoin: { communityId: _vm.communityId },
                      width: _vm.widthInner,
                    },
                    on: { onChange: _vm.onUserChange },
                    model: {
                      value: _vm.form.userId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userId", $$v)
                      },
                      expression: "form.userId",
                    },
                  },
                  "v-select2",
                  _vm.userParams,
                  false
                )
              ),
              _c("add-user", {
                attrs: { communityId: _vm.communityId },
                on: { getNewUser: _vm.onAddUserSuccess },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "房号信息",
                prop: "houseId",
                rules: [
                  { required: true, message: "请选择房号", trigger: "change" },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.houseOps, width: _vm.widthInner },
                on: { change: _vm.onHouseChange },
                model: {
                  value: _vm.form.houseId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "houseId", $$v)
                  },
                  expression: "form.houseId",
                },
              }),
            ],
            1
          ),
          _c("h4", [_vm._v(_vm._s(_vm.storeName))]),
          _c(
            "div",
            { staticClass: "deliveryType" },
            _vm._l(_vm.deliveryTypeOps, function (type, index) {
              return _c("div", { key: index }, [
                type.value === "3"
                  ? _c("span", [_vm._v("支持" + _vm._s(type.text))])
                  : _vm._e(),
                type.value === "2"
                  ? _c("span", [
                      _vm._v(
                        "支持" +
                          _vm._s(type.text) +
                          "：起送价格￥" +
                          _vm._s(_vm.onsiteMinprice) +
                          "元，" +
                          _vm._s(
                            _vm.freightMoney > 0
                              ? _vm.minMoney > 0
                                ? `服务费￥${_vm.freightMoney}元, 满￥${_vm.minMoney}元免服务费`
                                : "全场免服务费"
                              : "全场免服务费"
                          )
                      ),
                    ])
                  : _vm._e(),
                type.value === "1"
                  ? _c("span", [
                      _vm._v(
                        "支持" +
                          _vm._s(type.text) +
                          "：" +
                          _vm._s(
                            _vm.freightMoney > 0
                              ? _vm.minMoney > 0
                                ? `运费￥${_vm.freightMoney}元，满￥${_vm.minMoney}元包邮`
                                : "全场包邮"
                              : "全场免运费"
                          )
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品自定义分类",
                prop: "category",
                rules: [
                  {
                    required: true,
                    message: "请选择商品分类",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _vm.categoryList.length
                ? _c("v-select", {
                    attrs: { width: 180, options: _vm.categoryList },
                    on: { change: _vm.onCategoryChange },
                    model: {
                      value: _vm.form.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "category", $$v)
                      },
                      expression: "form.category",
                    },
                  })
                : _c("div", [_vm._v("暂无分类")]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择商品" } },
            [
              _c("v-select", {
                class: { "select-in-right": _vm.isRight },
                staticStyle: { "margin-right": "15px" },
                attrs: {
                  disabled: !_vm.canSelectProduct,
                  width: _vm.widthInner,
                  options: _vm.goodsOps,
                },
                on: { change: _vm.onGoodsSelectChange },
                model: {
                  value: _vm.curGoods.productId,
                  callback: function ($$v) {
                    _vm.$set(_vm.curGoods, "productId", $$v)
                  },
                  expression: "curGoods.productId",
                },
              }),
              _c("v-input-number", {
                staticStyle: { "margin-right": "15px" },
                attrs: { placeholder: "数量", width: 100 },
                model: {
                  value: _vm.curGoods.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.curGoods, "amount", $$v)
                  },
                  expression: "curGoods.amount",
                },
              }),
              _c("v-button", { on: { click: _vm.addItem } }, [_vm._v("添加")]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "已选清单",
                prop: "goodslist",
                rules: {
                  required: true,
                  message: "请添加商品",
                  trigger: "change",
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "goods-list",
                  class: {
                    "in-right": _vm.isRight,
                    "in-left-half": _vm.leftIndex > 1,
                  },
                },
                [
                  _vm._l(_vm.form.goodslist, function (item, index) {
                    return _c(
                      "div",
                      { key: `g_${index}`, staticClass: "goods-item" },
                      [
                        _c("div", { staticClass: "goods-name" }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                        _c("div", { staticClass: "goods-amount" }, [
                          _vm._v(_vm._s(item.amountText)),
                        ]),
                        _c("div", { staticClass: "goods-price" }, [
                          _vm._v(_vm._s(item.productPriceText)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "operates" },
                          [
                            _c(
                              "v-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItem(item, index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.form.goodslist.length &&
                            _vm.freightMoneyText &&
                            _vm.freightMoney,
                          expression:
                            "form.goodslist.length && freightMoneyText && freightMoney",
                        },
                      ],
                      staticClass: "goods-item total",
                    },
                    [
                      _c("div", { staticClass: "goods-name" }, [
                        _vm._v(_vm._s(_vm.freightMoneyText)),
                      ]),
                      _c("div", { staticClass: "goods-amount" }),
                      _c("div", { staticClass: "goods-price" }, [
                        _c(
                          "span",
                          { class: { "delete-line": _vm.deleteLineVisible } },
                          [_vm._v(" ￥" + _vm._s(_vm.freightMoney))]
                        ),
                        _vm.deleteLineVisible
                          ? _c("span", [_vm._v("￥0.00")])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.form.goodslist.length &&
                            _vm.freightMoneyText &&
                            _vm.minMoney !== -1 &&
                            _vm.freightMoney,
                          expression:
                            "form.goodslist.length && freightMoneyText && minMoney !== -1 && freightMoney",
                        },
                      ],
                      staticClass: "goods-item total",
                    },
                    [
                      _c("div", { staticClass: "goods-amount" }),
                      _c("div", { staticClass: "goods-price" }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.goodslist.length,
                          expression: "form.goodslist.length",
                        },
                      ],
                      staticClass: "goods-item total",
                    },
                    [
                      _c("div", { staticClass: "goods-name" }, [
                        _vm._v("总计"),
                      ]),
                      _c("div", { staticClass: "goods-amount" }),
                      _c("div", { staticClass: "goods-price" }, [
                        _vm._v("￥" + _vm._s(_vm.totalPrice)),
                      ]),
                    ]
                  ),
                ],
                2
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物流方式",
                prop: "deliveryType",
                rules: [
                  {
                    required: true,
                    message: "请选择物流方式",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { width: 180, options: _vm.deliveryTypeOps },
                on: { change: _vm.onDeliveryTypeChange },
                model: {
                  value: _vm.form.deliveryType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliveryType", $$v)
                  },
                  expression: "form.deliveryType",
                },
              }),
            ],
            1
          ),
          _vm.isOpenTime
            ? _c(
                "el-form-item",
                {
                  staticClass: "form-item-group",
                  attrs: { label: "服务时间", rules: [{ required: true }] },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "inline-form-item",
                      attrs: {
                        prop: "orderDate",
                        rules: [
                          {
                            required: true,
                            message: "请选择服务日期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.orderDateOps },
                        on: { change: _vm.onDateChange },
                        model: {
                          value: _vm.form.orderDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orderDate", $$v)
                          },
                          expression: "form.orderDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "inline-form-item",
                      attrs: {
                        prop: "orderTime",
                        rules: [
                          {
                            required: true,
                            message: "请选择服务时间",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.orderTimeOps },
                        model: {
                          value: _vm.form.orderTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orderTime", $$v)
                          },
                          expression: "form.orderTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "v-button",
                {
                  attrs: { disabled: _vm.submitDisabled },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }