<template>
  <div>
    <el-form ref="form" class="service-form" :model="form" label-width="130px">
      <el-form-item label="用户信息" prop="userId" :rules="[{ required: true, message: '请选择用户', trigger: 'change' }]">
        <v-select2
          ref="userSelect"
          :handleData="handleUserData"
          v-model="form.userId"
          placeholder="查询用户"
          v-bind="userParams"
          :subjoin="{ communityId: communityId } "
          :width="widthInner"
          @onChange="onUserChange"></v-select2>
        <add-user :communityId="communityId" @getNewUser="onAddUserSuccess"/>
      </el-form-item>
      <el-form-item label="房号信息" prop="houseId" :rules="[{ required: true, message: '请选择房号', trigger: 'change' }]">
        <v-select v-model="form.houseId" :options="houseOps" :width="widthInner" @change="onHouseChange"></v-select>
      </el-form-item>
      <h4>{{storeName}}</h4>
      <div class="deliveryType">
        <div v-for="(type,index) in deliveryTypeOps" :key="index">
          <span v-if="type.value==='3'">支持{{type.text}}</span>
          <span v-if="type.value==='2'">支持{{type.text}}：起送价格￥{{onsiteMinprice}}元，{{ freightMoney > 0 ? ( minMoney >0 ? `服务费￥${freightMoney}元, 满￥${minMoney}元免服务费` : '全场免服务费') : '全场免服务费'}}</span>
          <span v-if="type.value==='1'">支持{{type.text}}：{{ freightMoney > 0 ? ( minMoney >0 ? `运费￥${freightMoney}元，满￥${minMoney}元包邮` : '全场包邮') : '全场免运费'}}</span>
        </div>
      </div>
      <el-form-item label="商品自定义分类" prop="category" :rules="[{ required: true, message: '请选择商品分类', trigger: 'change' }]">
        <v-select v-if="categoryList.length" :width="180" v-model="form.category" :options="categoryList" @change="onCategoryChange" />
        <div v-else>暂无分类</div>
      </el-form-item>
      <el-form-item label="选择商品">
        <v-select :disabled="!canSelectProduct" v-model="curGoods.productId" :width="widthInner" :class="{ 'select-in-right': isRight }" style="margin-right: 15px" :options="goodsOps" @change="onGoodsSelectChange" />
        <v-input-number  v-model="curGoods.amount" placeholder="数量" :width="100" style="margin-right: 15px;" />
        <v-button @click="addItem">添加</v-button>
      </el-form-item>
      <el-form-item label="已选清单" prop="goodslist" :rules="{ required: true, message: '请添加商品', trigger: 'change' }">
        <div class="goods-list" :class="{'in-right': isRight, 'in-left-half': leftIndex > 1}">
          <div class="goods-item" v-for="(item, index) in form.goodslist" :key="`g_${index}`">
            <div class="goods-name">{{item.text}}</div>
            <div class="goods-amount">{{item.amountText}}</div>
            <div class="goods-price">{{item.productPriceText}}</div>
            <div class="operates">
              <v-button type="danger" @click="removeItem(item, index)">删除</v-button>
            </div>
          </div>
          <div class="goods-item total" v-show="form.goodslist.length && freightMoneyText && freightMoney">
            <div class="goods-name">{{freightMoneyText}}</div>
            <div class="goods-amount"></div>
            <div class="goods-price" >
              <span :class="{ 'delete-line': deleteLineVisible }"> ￥{{freightMoney}}</span>
              <span v-if="deleteLineVisible">￥0.00</span>
            </div>
          </div>
          <div class="goods-item total" v-show="form.goodslist.length && freightMoneyText && minMoney !== -1 && freightMoney">
            <div class="goods-amount"></div>
            <div class="goods-price"></div>
          </div>
          <div class="goods-item total" v-show="form.goodslist.length">
            <div class="goods-name">总计</div>
            <div class="goods-amount"></div>
            <div class="goods-price">￥{{totalPrice}}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="物流方式" prop="deliveryType" :rules="[{ required: true, message: '请选择物流方式', trigger: 'change' }]">
        <v-select v-model="form.deliveryType" :width="180" :options="deliveryTypeOps" @change="onDeliveryTypeChange" />
      </el-form-item>
      <el-form-item label="服务时间" v-if="isOpenTime" class="form-item-group" :rules="[{ required: true }]">
        <el-form-item class="inline-form-item" prop="orderDate" :rules="[{ required: true, message: '请选择服务日期', trigger: 'change' }]">
          <v-select v-model="form.orderDate" :options="orderDateOps" @change="onDateChange"></v-select>
        </el-form-item>
        <el-form-item class="inline-form-item" prop="orderTime" :rules="[{ required: true, message: '请选择服务时间', trigger: 'change' }]">
          <v-select v-model="form.orderTime" :options="orderTimeOps"></v-select>
        </el-form-item>
      </el-form-item>
      <el-form-item>
        <v-button :disabled="submitDisabled" @click="save">保存</v-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AddUser from './addUser'
import { getServiceNameURL, queryCategroyURL, queryGoodsListURL, queryHouseURL, queryStoreDetalURL, saveShopOrderURL,
  userParams
} from './api'

import { handleUserData } from './common'
const DeliveryTypeOps = [
  {
    text: '快递配送',
    value: '1'
  },
  {
    text: '预约上门',
    value: '2'
  },
  {
    text: '用户自提',
    value: '3'
  }
]
let ServiceTimeList = []
export default {
  name: 'service',
  props: {
    width: Number,
    communityId: {
      type: String,
      default: '4829905d-cb9e-11e8-8a58-506b4b417204'
    },
    leftIndex: Number
  },
  components: {
    AddUser
  },
  computed: {
    widthInner () {
      if (this.leftIndex === -1 || this.leftIndex > 1) {
        return 320
      } else {
        return this.width
      }
    },
    isRight () {
      return this.leftIndex === -1
    },
    freightMoneyText () {
      if (this.form.deliveryType === '2') {
        return '服务费'
      } else if (this.form.deliveryType === '1') {
        return '运费'
      } else {
        return ''
      }
    },
    submitDisabled () {
      if (this.totalPrice < this.onsiteMinprice && this.form.deliveryType === '2') {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    communityId () {
      this.clearFormData()
      this.onsiteMinprice = 0
      this.freightMoney = undefined
      this.minMoney = undefined
      this.queryServiceName()
    }
  },
  data () {
    return {
      form: {
        userId: undefined,
        orgId: undefined,
        category: undefined,
        houseId: undefined,
        deliveryType: undefined,
        orderDate: undefined,
        orderTime: undefined,
        userAddress: '', // 当前用户的房号信息
        goodslist: []
      },
      userParams,
      houseOps: [
        {
          label: '全部',
          value: undefined
        }
      ],
      curGoods: {
        product: undefined,
        amount: 1,
        productId: undefined
      },
      storeName: undefined,
      categoryList: [],
      goodsOps: [],
      totalPrice: 0,
      storeId: undefined,
      deliveryTypeOps: [],
      orderDateOps: [],
      orderTimeOps: [],
      timeZeroOps: [],
      timeNormalOps: [],
      isOpenTime: false,
      freightMoney: undefined,
      minMoney: undefined,
      deleteLineVisible: false,
      onsiteMinprice: 0,
      canSelectProduct: true
    }
  },
  methods: {
    addItem () {
      if (!this.curGoods.amount) {
        this.$message('请输入商品数量！')
        return false
      }
      if (!this.curGoods.product) {
        this.$message('请选择商品！')
        return false
      }
      const goods = this.curGoods.product
      let exsit = this.form.goodslist.find(item => this.curGoods.productId === item.productId)
      if (exsit) {
        exsit.amount = exsit.amount + this.curGoods.amount
      } else {
        this.form.goodslist.push({
          ...goods,
          amount: this.curGoods.amount
        })
      }
      this.form.goodslist = this.form.goodslist.map(item => ({
        ...item,
        amountText: item.amount ? `X ${item.amount}[${item.productUnit}]` : '',
        productPriceText: item.productPrice ? `￥${item.productPrice * item.amount}` : '',
        totalPrice: item.productPrice * item.amount
      }))
      this.calculatePrice()
    },
    handleUserData,
    async queryServiceName () {
      if (!this.communityId || this.communityId.length === 0) {
        return
      }
      let { data } = await this.$axios.get(getServiceNameURL, {
        params: {
          communityId: this.communityId
        }
      })
      this.storeName = data.storeName
      this.busId = data.busId
      this.storeId = data.id
      this.queryCategroy()
      this.queryStoreDetailInfo()
    },
    async queryCategroy () {
      let { data } = await this.$axios.get(queryCategroyURL, {
        params: {
          busId: this.busId
        }
      })
      this.categoryList = data.map(item => {
        return {
          text: item.categoryName,
          value: item.id
        }
      })
      // 有送水服务的自定义分类时，自动选中送水服务
      let target = this.categoryList.find(item => item.text === '送水服务')
      if (target) {
        this.form.category = target.value
      }
      this.onCategoryChange()
    },
    async queryGoodsList () {
      this.goodsOps = []
      this.canSelectProduct = false
      let { data } = await this.$axios.get(queryGoodsListURL, {
        params: {
          communityId: this.communityId,
          labelId: this.form.category,
          storeId: this.storeId
        }
      })
      if (data && data.length) {
        this.goodsOps = data.map(item => {
          return {
            ...item,
            text: item.productInfo,
            value: item.productId
          }
        }) || []
        // 自动选一个
        if (this.goodsOps.length) {
          this.curGoods.productId = this.goodsOps[0].value
          this.freightMoney = this.goodsOps[0].freightMoney
          this.minMoney = this.goodsOps[0].minMoney
          this.onsiteMinprice = this.goodsOps[0].onsiteMinprice
        }
      }
      this.canSelectProduct = true
    },
    async queryHouseList () {
      let { data } = await this.$axios.get(queryHouseURL, {
        params: {
          communityId: this.communityId,
          userId: this.form.userId,
          orgId: this.form.orgId
        }
      })
      this.houseOps = data.map(item => {
        let nameArray = []
        let keys = ['communityName', 'address', 'buildNum', 'unitNum', 'roomNum']
        keys.forEach(key => {
          if (item[key]) {
            nameArray.push(item[key])
          }
        })
        let name = nameArray.join('-')
        return {
          ...item,
          text: name,
          value: item.roomId
        }
      })
      if (this.houseOps.length === 1) {
        this.form.houseId = this.houseOps[0].value
      }
    },
    onUserChange (item) {
      if (item) {
        this.form.orgId = item.orgId
        this.form.userName = item.userName
        this.form.userPhone = item.mobileNum
        this.queryHouseList()
      } else {
        this.houseOps = []
      }
      this.form.houseId = undefined
    },
    onHouseChange (value, item) {
      if (item) {
        this.form.userAddress = item.text
      }
    },
    onCategoryChange () {
      this.curGoods = {
        product: undefined,
        amount: 1,
        productId: undefined
      }
      this.queryGoodsList()
    },
    removeItem (item, index) {
      this.form.goodslist.splice(index, 1)
      this.calculatePrice()
    },
    // 查询物流方式、服务时间
    async queryStoreDetailInfo () {
      let { data } = await this.$axios.get(queryStoreDetalURL, {
        params: {
          storeId: this.storeId
        }
      })
      if (data && data.deliveryType) {
        this.deliveryTypeOps = data.deliveryType.split(',').map(value => DeliveryTypeOps.find(item => item.value === value))
      }
      if (data && data.serviceTimeList) {
        ServiceTimeList = data.serviceTimeList
      }
      this.form.deliveryType = this.deliveryTypeOps[0].value
      this.onDeliveryTypeChange()
    },
    onDeliveryTypeChange () {
      this.form.orderDate = undefined
      this.form.orderTime = undefined
      let obj = ServiceTimeList.find(item => this.form.deliveryType === String(item.deliveryType))
      if (obj) {
        this.orderDateOps = obj.days.map(text => ({ text, value: text }))
        this.timeNormalOps = obj.timeNormal.map(text => ({ text, value: text }))
        this.timeZeroOps = obj.timeZero.map(text => ({ text, value: text }))
      } else {
        this.orderDateOps = []
        this.timeNormalOps = []
        this.timeZeroOps = []
      }
      this.isOpenTime = this.orderDateOps.length && this.timeNormalOps.length
      this.calculatePrice()
      // 获取起送金额
      if (this.form.goodslist.length) {
        this.onsiteMinprice = this.form.goodslist[0].onsiteMinprice
      } else {
        this.onsiteMinprice = 0
      }
    },
    onDateChange (date) {
      let index = this.orderDateOps.findIndex(item => item.value === date)
      if (index) {
        this.orderTimeOps = this.timeNormalOps
      } else {
        this.orderTimeOps = this.timeZeroOps
      }
    },
    async save () {
      try {
        await this.$refs.form.validate()
      } catch (error) {
        return false
      }
      let productInfo = this.form.goodslist.map(item => ({
        itemNum: item.amount,
        itemName: item.productName,
        itemPrice: item.productPrice,
        itemUnit: item.productUnit,
        specId: item.productId
      }))
      let minMoney = this.form.goodslist[0].minMoney
      let freightMoney = 0 // 运费或服务费
      let productPrice = 0
      this.form.goodslist.forEach(item => {
        productPrice += item.totalPrice
      })
      if (this.form.deliveryType === '1' || this.form.deliveryType === '2') {
        freightMoney = this.form.goodslist[0].freightMoney
        if (productPrice - minMoney >= 0 && minMoney !== -1) {
          freightMoney = 0
        }
      }
      let orderPrice = this.totalPrice.toFixed(2)
      let params = {
        ...this.form,
        addressId: this.form.houseId,
        communityId: this.communityId,
        moneyType: '0',
        productInfo,
        storeId: this.form.goodslist[0].storeId,
        shopId: this.form.goodslist[0].busId,
        imageUrls: JSON.stringify([]),
        orderPrice,
        freightMoney
      }
      // 减少入参
      delete params.userId
      delete params.category
      delete params.goodslist
      let { status } = await this.$axios.post(saveShopOrderURL, this.$qs.stringify({
        orderInfo: JSON.stringify([params]),
        userId: this.form.userId,
        totalMoney: orderPrice
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      })

      if (status === 100) {
        this.$message.success('下单成功')
        this.clearFormData()
      }
    },
    onGoodsSelectChange (value, item) {
      this.curGoods.product = item
    },
    // 清空表单提交数据
    clearFormData () {
      this.storeName = undefined
      this.deliveryTypeOps = []
      this.form = {
        userId: undefined,
        orgId: undefined,
        category: undefined,
        houseId: undefined,
        deliveryType: undefined,
        orderDate: undefined,
        orderTime: undefined,
        userAddress: '' // 当前用户的房号信息
      }
      this.curGoods = {
        product: undefined,
        amount: 1,
        productId: undefined
      }
      this.form.goodslist = []
      this.goodsOps = []
      this.totalPrice = 0
      this.$refs.userSelect.clearValue()
      this.$refs.form.resetFields()
    },
    onAddUserSuccess (data) {
      this.form.userId = data
    },
    // 计算总价及运费
    calculatePrice () {
      if (!this.form.goodslist.length) {
        return false
      }
      // 计算总价
      let sum = 0
      this.form.goodslist.forEach(item => {
        sum += item.totalPrice
      })
      this.totalPrice = sum
      // 计算运费
      if (this.form.deliveryType === '1' || this.form.deliveryType === '2') {
        this.freightMoney = this.form.goodslist[0].freightMoney
        this.minMoney = this.form.goodslist[0].minMoney
        // minMoney为-1表示没有包邮活动
        if (this.totalPrice < this.minMoney || this.minMoney === -1) {
          this.totalPrice = this.totalPrice + this.freightMoney
          this.deleteLineVisible = false
        } else {
          this.deleteLineVisible = true
        }
      }
    }
  },
  mounted () {
    this.queryServiceName()
  }
}
</script>

<style lang="scss" scoped>
  .deliveryType {
    margin-bottom:20px;
    span {
      line-height:24px;
    }
  }
  .goods-list {
    float: left;
    width: 100%;
    &.in-right, &.in-left-half {
      width: 100%;
      .goods-item {
        flex-wrap: wrap;
        justify-content: flex-end;
        &.total {
          flex-wrap: nowrap;
        }
        .goods-name {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
    .goods-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .goods-name {
        width: 50%;
        line-height: 1.3;
      }
      .goods-amount {
        width: 120px;
        text-align: center;
      }
      .goods-price {
        width: 100px;
        .delete-line {
          text-decoration: line-through;
        }
      }
    }
  }
  .select-in-right {
    margin-bottom: 15px;
  }
  .inline-form-item {
    display: inline-block;
    margin-right: 15px;
  }
</style>
