const handleUserData = (data) => {
  return data.map(item => {
    let arrName = []
    let keys = ['typeName', 'userName', 'mobileNum', 'orgName']
    keys.forEach(key => {
      if (item[key]) {
        arrName.push(item[key])
      }
    })
    let name = arrName.join(' | ')
    let id = item.userId
    return {
      ...item,
      name,
      id
    }
  })
}

export {
  handleUserData
}
