<template>
  <div class="diposit-container">
    <el-form class="dipositForm" ref="form" :model="form" label-width="120px">
      <el-form-item label="选择分类" prop="type" :rules="[{ required: true, message: '请选择分类', trigger: 'blur' }]">
         <checkbox-plus :options="checkoptions" :value.sync="form.type" />
      </el-form-item>
      <el-form-item label="业主信息" prop="userId" :rules="[{ required: true, message: '请选择用户', trigger: 'change' }]">
        <v-select2 ref="userSelect" :handleData="handleUserData" v-model="form.userId" placeholder="查询用户" v-bind="userParams" :subjoin="communitySubjoin" @onChange="userChange"  :width="width"></v-select2>
        <add-user :communityId="communityId" :userSource="7" @getNewUser="userId => form.userId = userId" />
      </el-form-item>
      <el-form-item label="房号" prop="roomId" :rules="[{ required: true, message: '请选择房号', trigger: 'change' }]">
        <v-select v-model="form.roomId" :options="houseOps" :width="width"></v-select>
      </el-form-item>
      <el-form-item label="寄存地点" prop="address" :rules="[{ required: true, message: '请输入寄存地点', trigger: 'blur' }]">
         <v-input v-model="form.address" placeholder="支持最长输入10位" :width="width" :maxlength="10"></v-input>
      </el-form-item>
      <el-form-item label="物品照片">
        <v-uploader :action="uploadURL" :imgUrls.sync="form.imageUrls" :limit="4">
          <!-- <template #tip>
            <span>注：建议宽1080px高810px，图片小于512KB</span>
          </template> -->
        </v-uploader>
      </el-form-item>
      <el-form-item label="物品内容" prop="remark" :rules="[{ required: true, message: '请填写物品内容', trigger: 'blur' }]">
         <v-textarea  v-model="form.remark" :rows="3" :width="width" :maxlength="80"></v-textarea>
      </el-form-item>
      <el-form-item>
        <v-button :width="width" @click="saveDiposit" :disabled="clickDisabel">确认提交</v-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  userParams,
  uploadURL,
  queryHouseURL,
  saveDepositURL
} from './api'
import {
  handleUserData
} from './common'
import { CheckboxPlus } from 'components/bussiness'
import { vUploader } from 'components/control'
import AddUser from './addUser'
export default {
  components: {
    CheckboxPlus,
    vUploader,
    AddUser
  },
  props: {
    width: Number,
    communityId: {
      type: String,
      default: '4829905d-cb9e-11e8-8a58-506b4b417204'
    }
  },
  data () {
    return {
      userParams,
      handleUserData,
      uploadURL,
      checkoptions: [{
        label: '业主寄存',
        value: '1'
      }, {
        label: '他人寄存',
        value: '2'
      }],
      houseOps: [],
      form: {
        type: '1',
        userId: '',
        roomId: undefined,
        address: '',
        imageUrls: [],
        remark: ''
      },
      clickDisabel: false
    }
  },
  computed: {
    communitySubjoin () {
      return {
        communityId: this.communityId
      }
    }
  },
  created () {

  },
  methods: {
    // user 变化获取房号
    userChange (item) {
      if (item && item.communityId) {
        const params = {
          communityId: item.communityId,
          orgId: item.orgId,
          userId: item.userId
        }
        let _this_ = this
        _this_.$axios.get(queryHouseURL, { params }).then(res => {
          if (res.status === 100) {
            let data = res.data
            if (data && data.length) {
              data = data.map(item => {
                let address = ''
                const buildNum = item.buildNum ? ('-' + item.buildNum) : ''
                const unitNum = item.unitNum ? ('-' + item.unitNum) : ''
                address = item.communityName + buildNum + unitNum + '-' + item.roomNum
                return {
                  value: item.roomId,
                  text: address
                }
              })
              _this_.houseOps = data
              if (data.length === 1) {
                _this_.form.roomId = data[0].value
              }
            }
          }
        })
      }
    },
    saveDiposit () {
      let _this_ = this
      let postData = this.form
      postData.communityId = this.communityId
      this.clickDisabel = true
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios.post(`${saveDepositURL}?${_this_.$qs.stringify(postData)}`).then(res => {
            _this_.clickDisabel = false
            if (res.status === 100) {
              _this_.$message({
                type: 'success',
                message: '物品寄存成功',
                center: true
              })
              _this_.$refs.userSelect.clearValue()
              _this_.$refs.form.resetFields()
              _this_.form.imageUrls = []
            }
          })
        }
      })
    },
    clearFormData () {
      this.houseOps = []
      this.form.userId = ''
      this.form.roomId = undefined
    }
  },
  watch: {
    width (newVal) {
      console.log('order', this.width)
    },
    communityId () {
      this.clearFormData()
    }
  }
}
</script>
<style lang="scss" scoped>
.diposit-container {
  .dipositForm {
    width: 100%;
    > .el-form-item {
      text-align: left;
      margin-bottom: 16px;
    }
    .checkbox {
      width:128px;
    }
  }
}
</style>
