<template>
  <div class="express-container">
    <div class="switch" v-if="!active">
      <div class="bussiness-container">
        <div class="bussiness-item" v-for="(item,index) in bussinessItems" :key="index" @click="switchItem(item)">
           <img :src="item.icon" />
           <span class="item-title">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <el-form v-if="active === 1" class="expressForm" ref="form" :model="form" label-width="80px">
      <div class="gbBtn">
        <v-button @click="active=undefined">返回</v-button>
      </div>
      <el-form-item label="快递单号" prop="expressNo" :rules="[{ required: true, message: '请输入快递单号', trigger: 'blur' }]">
        <v-input v-model="form.expressNo" :width="calcWidth" @input="getExpCompanyOps"></v-input>
      </el-form-item>
      <el-form-item label="快递公司" prop="expressCompanyId" :rules="[{ required: true, message: '请选择快递公司名称', trigger: 'blur' }]">
         <v-select v-model="form.expressCompanyId" :options="expressCompanyOps" @change="expCompanyChange" :width="calcWidth"></v-select>
      </el-form-item>
      <el-form-item label="收件人" prop="userId" :rules="[{ required: true, message: '请选择用户', trigger: 'change' }]">
        <v-select2 ref="userSelect" :handleData="handleUserData" v-model="form.userId" placeholder="查询用户" v-bind="userParams" :subjoin="communitySubjoin" @onChange="userChange" :width="calcWidth"></v-select2>
        <add-user :communityId="communityId" :userSource="2" @getNewUser="getNewUser" />
      </el-form-item>
      <el-form-item label="房号" prop="roomId" :rules="[{ required: true, message: '请选择房号', trigger: 'change' }]">
        <v-select v-model="form.roomId" :options="houseOps" :width="calcWidth"></v-select>
      </el-form-item>
      <el-form-item label="其他电话" prop="otherPhone">
         <v-input v-model="form.otherPhone" :width="calcWidth" :maxlength="11"></v-input>
      </el-form-item>
      <el-form-item label="存放位置" prop="expressLocation" :rules="[{ required: true, message: '请选择存放位置', trigger: 'change' }]">
         <v-select v-model="form.expressLocation" :options="storeOps" :width="calcWidth"></v-select>
      </el-form-item>
      <el-form-item>
        <v-button :width="calcWidth" @click="saveExpress">确认提交</v-button>
      </el-form-item>
    </el-form>
    <el-form v-if="active === 2" class="expressForm" ref="signForm" :model="signForm" label-width="100px">
      <div class="gbBtn">
        <v-button @click="active=undefined">返回</v-button>
      </div>
      <el-form-item label="取件二维码" prop="expCode" :rules="[{ required: true, message: '请输入取件二维码', trigger: 'blur' }]">
        <v-input v-model="signForm.expCode" placeholder="请输入9位取件二维码" :width="calcWidth" :maxlength="9"></v-input>
      </el-form-item>
      <el-form-item>

        <div class="tips">
          <span>取件二维码可以在哪里找到：</span>
          <span>1.APP用户可在通知-快递分类下中找到，也可以到"我的 > 我的快件"中找到。</span>
          <span>2.非APP用户可到收件人的手机短信中找到。</span>
        </div>
        <v-button :width="calcWidth" @click="getExpQrcode">查询</v-button>
      </el-form-item>
    </el-form>
    <el-dialog width="600" title="快递信息" :visible.sync="dialogVisible">
        <div class="signInfo">
          <span class="span">{{ `收件人：${signInfo.userName}`}}</span>
          <span class="span">{{ `手机号：${signInfo.userPhone}`}}</span>
          <span class="span">{{ `房号：${signInfo.roomAddress}`}}</span>
        </div>
        <table-panel
            :headers="tableHeader"
            :tableData="signExpList"
            :isMultiSelect="true"
            :hasOperateColumn="false"
            @select="select"
            @selectAll="selectAll">
        </table-panel>
        <div slot="footer" class="dialog-footer">
          <v-button type="default" text="取 消" @click="dialogVisible=false"></v-button>
          <v-button style="margin-left:10px" text="领 取" @click="signCheckedExp"></v-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  userParams,
  getCommunityExpressStoreURL,
  getExpListURL,
  queryHouseURL,
  getExpCompanyListByExpNumURL,
  saveExpressURL,
  signExpListURL
} from './api'
import {
  handleUserData
} from './common'
import AddUser from './addUser'
import { TablePanel } from 'components/bussiness'
import Vue from 'vue'
import { Checkbox, CheckboxGroup } from 'element-ui'
Vue.use(Checkbox).use(CheckboxGroup)

const ICON = {
  record: require('./images/record.png'),
  express: require('./images/express.png')
}

export default {
  components: {
    AddUser,
    TablePanel
  },
  props: {
    width: Number,
    communityId: {
      type: String,
      default: '4829905d-cb9e-11e8-8a58-506b4b417204'
    }
  },
  computed: {
    calcWidth () {
      return this.width * 0.65
    },
    communitySubjoin () {
      return {
        communityId: this.communityId
      }
    }
  },
  data () {
    return {
      active: undefined,
      userParams,
      handleUserData,
      houseOps: [],
      expressCompanyOps: [],
      subjectOps: [],
      secondOps: [],
      storeOps: [],
      form: {
        expressNo: '',
        expressCompany: '',
        expressCompanyId: undefined,
        expressLocation: undefined,
        otherPhone: '',
        roomId: undefined,
        userId: ''
      },
      signForm: {
        expCode: ''
      },
      tableHeader: [{
        prop: 'expNo',
        label: '快递单号'
      }, {
        prop: 'expFirm',
        label: '快递公司'
      }, {
        prop: 'expMemo',
        label: '存放位置'
      }],
      signExpList: [],
      signInfo: {
        userName: '',
        userPhone: '',
        roomAddress: ''
      },
      checkedList: [],
      bussinessItems: [{
        icon: ICON.record,
        name: '快递录入',
        value: 1
      }, {
        icon: ICON.express,
        name: '快递领取',
        value: 2
      }],
      dialogVisible: false
    }
  },
  created () {
    this.getExpressStoreList()
  },
  methods: {
    // 根据单号获取快递公司
    getExpCompanyOps () {
      let _this_ = this
      const params = {
        expNum: this.form.expressNo
      }
      if (this.form.expressNo) {
        _this_.$axios.get(getExpCompanyListByExpNumURL, { params }).then(res => {
          if (res.status === 100) {
            let data = res.data
            if (data && data.length) {
              data = data.map(item => {
                return {
                  value: item.expCode,
                  text: item.expFirm
                }
              })
            } else {
              data = [{
                value: 'qita',
                text: '其他'
              }]
            }
            _this_.expressCompanyOps = data
          }
        })
      }
    },
    expCompanyChange (selected, selectedOption) {
      if (selectedOption && selectedOption.text) {
        this.form.expressCompany = selectedOption.text
      }
    },
    // 根据园区获取寄存地点
    getExpressStoreList () {
      let _this_ = this
      const params = {
        communityId: this.communityId
      }
      _this_.$axios.get(getCommunityExpressStoreURL, { params }).then(res => {
        if (res.status === 100) {
          let data = res.data
          if (data && data.length) {
            data = data.map(item => {
              return {
                value: item,
                text: item
              }
            })
            _this_.storeOps = data
          }
        }
      })
    },
    // user 变化获取房号
    userChange (item) {
      if (item && item.communityId) {
        const params = {
          communityId: item.communityId,
          orgId: item.orgId,
          userId: item.userId
        }
        let _this_ = this
        _this_.$axios.get(queryHouseURL, { params }).then(res => {
          if (res.status === 100) {
            let data = res.data
            if (data && data.length) {
              data = data.map(item => {
                let address = ''
                const buildNum = item.buildNum ? ('-' + item.buildNum) : ''
                const unitNum = item.unitNum ? ('-' + item.unitNum) : ''
                address = item.communityName + buildNum + unitNum + '-' + item.roomNum
                return {
                  value: item.roomId,
                  text: address
                }
              })
              _this_.houseOps = data
              if (data.length === 1) {
                _this_.form.roomId = data[0].value
              }
            }
          }
        })
      }
    },
    // 保存快递信息
    saveExpress () {
      let _this_ = this
      let postData = this.form
      postData.communityId = this.communityId
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios.post(`${saveExpressURL}?${_this_.$qs.stringify(postData)}`).then(res => {
            if (res.status === 100) {
              _this_.$message({
                type: 'success',
                message: '录入成功',
                center: true
              })
              _this_.$refs.userSelect.clearValue()
              _this_.$refs.form.resetFields()
            }
          })
        }
      })
    },
    getExpQrcode () {
      let _this_ = this
      const params = {
        code: this.signForm.expCode,
        communityId: this.communityId
      }
      this.$refs['signForm'].validate((valid) => {
        if (valid) {
          _this_.$axios.get(getExpListURL, { params }).then(async res => {
            if (res.status === 100) {
              let data = res.data
              _this_.signInfo.userName = data.userName
              _this_.signInfo.userPhone = data.userPhone
              _this_.signInfo.roomAddress = data.roomAddress
              if (data.accepted === 1) {
                if (data.expInfoVList && data.expInfoVList.length) {
                  let isOk = await _this_.$confirm('还有其他快递未领取，是否去领取？', '该快递已被领取', {
                    confirmButtonText: '去领取',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
                  if (isOk) {
                    _this_.signExpList = data.expInfoVList
                    _this_.dialogVisible = true
                  }
                } else {
                  _this_.$alert('该快递已被领取', '标题名称', {
                    confirmButtonText: '确定'
                  })
                }
              } else {
                _this_.signExpList = data.expInfoVList
                _this_.dialogVisible = true
              }
            }
          })
        }
      })
    },
    select (selection, row) {
      if (selection.length) {
        this.checkedList = selection.map(item => item.id)
      } else {
        this.checkedList = []
      }
    },
    selectAll (selection) {
      if (selection.length) {
        this.checkedList = selection.map(item => item.id)
      } else {
        this.checkedList = []
      }
    },
    async signCheckedExp () {
      if (this.checkedList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择快递',
          center: false
        })
        return false
      } else {
        let isOk = await this.$confirm('确认领取')
        isOk && this.signExp()
      }
    },
    signExp () {
      let _this_ = this
      const postData = {
        ids: this.checkedList
      }
      this.$axios.post(`${signExpListURL}?${_this_.$qs.stringify(postData)}`).then(res => {
        if (res.status === 100) {
          _this_.$message({
            type: 'success',
            message: '领取成功',
            center: false
          })
          _this_.dialogVisible = false
          _this_.$refs.signForm.resetFields()
          _this_.signForm.expCode = ''
        }
      })
    },
    switchItem (item) {
      this.active = item.value
    },
    getNewUser (userId) {
      this.form.userId = userId
    },
    clearFormData () {
      this.active = undefined
      this.houseOps = []
      this.storeOps = []
      this.form.userId = ''
      this.form.roomId = undefined
    }
  },
  watch: {
    width (newVal) {
      console.log('order', this.width)
    },
    communityId () {
      this.clearFormData()
      this.getExpressStoreList()
    }
  }
}
</script>
<style lang="scss" scoped>
.express-container {
  text-align: left;
  .switch {
    padding-bottom: 16px;
  }
  .expressForm {
    .gbBtn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    > .el-form-item {
      text-align: left;
      margin-bottom: 16px;
    }
    .tips {
      line-height: 17px;
      color:#aaa;
      font-size:12px;
      margin-bottom: 50px;
      span {
        display: block;
      }
    }
  }
  .signInfo {
    padding-bottom: 8px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    .span {
      width: 50%;
      margin-bottom: 16px;
    }
  }
}
</style>
