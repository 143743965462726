var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addUser-container" },
    [
      _c(
        "v-button",
        { attrs: { type: "text" }, on: { click: _vm.dialogShow } },
        [_vm._v("\n    没有？去添加一条业主信息\n  ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.disable,
            width: "500px",
            title: "新增用户",
            "custom-class": "addUser-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.disable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addUserForm",
              staticClass: "addUserForm",
              attrs: { "label-width": "150px", model: _vm.form },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "苑幢",
                    rules: [
                      {
                        required: true,
                        message: "请选择苑幢",
                        trigger: "change",
                      },
                    ],
                    prop: "building",
                  },
                },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "building",
                        attrs: {
                          subjoin: { communityId: _vm.communityId },
                          width: _vm.width,
                          handleData: _vm.handleBuildingData,
                        },
                        on: { onChange: _vm.onBuildingChange },
                        model: {
                          value: _vm.form.building,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "building", $$v)
                          },
                          expression: "form.building",
                        },
                      },
                      "v-select2",
                      _vm.buildingParams,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm.hasHouseInfo
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "单元室",
                        rules: [
                          {
                            required: _vm.hasHouseInfo,
                            message: "请选择单元室",
                            trigger: "change",
                          },
                        ],
                        prop: "roomId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            ref: "roomId",
                            attrs: {
                              subjoin: _vm.roomExParams,
                              width: _vm.width,
                              handleData: _vm.handleRoomData,
                              disabled: !this.form.buildingNumber,
                            },
                            on: { onChange: _vm.onRoomChange },
                            model: {
                              value: _vm.form.roomId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "roomId", $$v)
                              },
                              expression: "form.roomId",
                            },
                          },
                          "v-select2",
                          _vm.roomParams,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户姓名",
                    rules: [
                      {
                        required: true,
                        message: "请输入用户姓名",
                        trigger: "blur",
                      },
                    ],
                    prop: "ownerName",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 10,
                      placeholder: "请输入用户姓名",
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.ownerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerName", $$v)
                      },
                      expression: "form.ownerName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户手机",
                    rules: [
                      {
                        required: true,
                        message: "请输入用户手机",
                        trigger: "blur",
                      },
                    ],
                    prop: "ownerPhone",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      width: _vm.width,
                      placeholder: "请输入用户手机号码",
                      maxlength: 11,
                    },
                    model: {
                      value: _vm.form.ownerPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerPhone", $$v)
                      },
                      expression: "form.ownerPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份",
                    rules: [
                      {
                        required: true,
                        message: "请选择身份",
                        trigger: "change",
                      },
                    ],
                    prop: "userType",
                  },
                },
                [
                  _c("v-select", {
                    attrs: { width: _vm.width, options: _vm.userTypeOps },
                    model: {
                      value: _vm.form.userType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userType", $$v)
                      },
                      expression: "form.userType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否付款联系人",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否付款联系人",
                        trigger: "change",
                      },
                    ],
                    prop: "isPayment",
                  },
                },
                [
                  _c("v-select", {
                    attrs: { width: _vm.width, options: _vm.isOps },
                    model: {
                      value: _vm.form.isPayment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isPayment", $$v)
                      },
                      expression: "form.isPayment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否短信联系人",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否短信联系人",
                        trigger: "change",
                      },
                    ],
                    prop: "isMessage",
                  },
                },
                [
                  _c("v-select", {
                    attrs: { width: _vm.width, options: _vm.isOps },
                    model: {
                      value: _vm.form.isMessage,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isMessage", $$v)
                      },
                      expression: "form.isMessage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { type: "default", text: "取消" },
                on: {
                  click: function ($event) {
                    _vm.disable = false
                  },
                },
              }),
              _c("v-button", {
                staticStyle: { "margin-left": "10px" },
                attrs: { text: "保存" },
                on: { click: _vm.submit },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }