import { mapHelper } from 'common/utils'
// 是否
const is = [
  {
    text: '否',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]

// 用户身份
const userType = [
  {
    text: '业主',
    value: 1
  },
  {
    text: '家属',
    value: 2
  },
  {
    text: '租客',
    value: 3
  },
  {
    text: '伙伴',
    value: 4
  },
  {
    text: '嘉宾',
    value: 5
  }
]

const {
  map: isMap,
  setOps: setIsOps
} = mapHelper.setMap(is)

const {
  map: userTypeMap,
  setOps: setUserTypeOps
} = mapHelper.setMap(userType)

export {
  isMap,
  setIsOps,
  userTypeMap,
  setUserTypeOps
}
